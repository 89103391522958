import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "locus-of-control",
      "style": {
        "position": "relative"
      }
    }}>{`Locus of Control`}<a parentName="h1" {...{
        "href": "#locus-of-control",
        "aria-label": "locus of control permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`We’ve developed a holistic view of the dimensions of regeneration and looked into the value of leverage points for those hoping to steer a complex adaptive system. Now we consider what may be the most important question of all: what can I do? What lies within my locus of control? `}</p>
    <p><a parentName="p" {...{
        "href": "https://donellameadows.org/archives/leverage-points-places-to-intervene-in-a-system/"
      }}>{`Donella Meadows identified`}</a>{` the most powerful leverage point as`}</p>
    <blockquote>
      <p parentName="blockquote">{`The power to `}<a parentName="p" {...{
          "href": "/learn/module-7/no-paradigm"
        }}>{`transcend paradigms`}</a></p>
    </blockquote>
    <p>{`A paradigm exists in a mind — it is the lens through which one looks at the world. And this lens can be reshaped through conscious effort. `}<em parentName="p">{`You`}</em>{` are a leverage point. `}</p>
    <p>{`This week we explore ways we can transcend our own selves to lead the societal transformation we hope to realize. Regeneration starts within. By living a life of flourishing, your incandescence can overflow into your family, your community, your workplace.`}</p>
    <h2 {...{
      "id": "preparation",
      "style": {
        "position": "relative"
      }
    }}>{`Preparation`}<a parentName="h2" {...{
        "href": "#preparation",
        "aria-label": "preparation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Please read the following:`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://hfh.fas.harvard.edu/files/pik/files/activitiesforflourishing_jppw.pdf"
        }}>{`Activities for Flourishing: An Evidence-Based Guide`}</a>{`, Tyler J. VanderWeele (2020);`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "http://www.ejnet.org/ej/principles.pdf"
        }}>{`The Principles of Environmental Justice (EJ)`}</a>{`, People of Color Environmental Leadership Summit (1991);`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://blog.curvelabs.eu/applied-regenerative-economics-2219d2e1087b"
        }}>{`Applied Regenerative Economics`}</a>{`, Louise Borreani (2021)`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://twitter.com/againstutopia/status/1581309352236834817"
        }}>{`The Future of ReFi as DAOs`}</a>{` by Jahed Momand.`}</li>
    </ol>
    <p>{`More Resources:`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://sacred-economics.com/read-online/"
        }}>{`Sacred Economics`}</a>{`, Charles Eisenstein (2012);`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://otherinter.net/research/positive-sum-worlds/"
        }}>{`Positive Sum Worlds: Remaking Public Goods`}</a>{`, Sam Hart, Toby Shorin, Laura Lotti (2021);`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://slatestarcodex.com/2014/07/30/meditations-on-moloch/"
        }}>{`Meditations on Moloch`}</a>{`, Scott Alexander (2014);`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://twitter.com/owocki/status/1458541350211575808?s=20&t=ceWwd02ZapFexX0Yoat0Gw"
        }}>{`Transcending the individualist-collectivist divide`}</a>{`, Kevin Owocki (2021);`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://gov.gitcoin.co/t/a-vision-for-a-pluralistic-civilizational-scale-infrastructure-for-funding-public-goods/9503"
        }}>{`A Vision for A Pluralistic Civilizational Scale Infrastructure for funding Public Goods`}</a>{`, Kevin Owocki (2021);`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.brookings.edu/wp-content/uploads/2021/12/Chapter-Five_Breakthrough.pdf"
        }}>{`Interspecies Money`}</a>{`, Jonathan Ledgard (2022)`}</li>
    </ol>
    <h2 {...{
      "id": "recording",
      "style": {
        "position": "relative"
      }
    }}>{`Recording`}<a parentName="h2" {...{
        "href": "#recording",
        "aria-label": "recording permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Video src="https://www.youtube-nocookie.com/embed/bF8ZnLDtwfc" mdxType="Video" />
    <p>{`🙏🌱💚`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      